

    html,
    body,
    #app {
        height: 100%;
        min-height: 100%;
        min-height: 100vh;
    }

    body {
        #app {
            .app-body {
                min-height: 100%;
                flex-grow: 1;
                display: flex;
                flex-direction: column;

                .page-content {
                    flex: 1 0 auto;
                }
            }
        }
    }
